@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("assets/Fonts/Poppins-Regular.ttf") format("truetype");
}

body {
  background-color: #F3F4F9;
  padding: 0;
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins';
}

a {
  color: inherit;
  text-decoration: none;
}

.text-truncate {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.DraftEditor-editorContainer {
  height: 250px !important;
}

.demo-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

::-webkit-scrollbar {
  background-color: #1890FF1a;
  width: 8px;
  height: 10px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #1890FF;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.template-table {
  background-image: '';
  background-repeat: 'no-repeat';
  background-attachment: 'fixed';
  background-position: 'center';
  border-collapse: 'collapse';
}

.report td {
  border: 1px #000080;
  border-style: solid;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  vertical-align: top;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
  color: #000080;
}

.normal {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  vertical-align: top;
  font-family: Verdana, Geneva, sans-serif;
  font-size: 12px;
  color: #000080;
  text-decoration: none;
}

.table-link {
  color: #000080;
  text-decoration: none;
}

.animated-logo {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(700px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(700px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(700px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(700px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(700px);
    transform: perspective(700px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  from {
    -webkit-transform: perspective(700px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(700px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  30% {
    -webkit-transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  50% {
    -webkit-transform: perspective(700px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(700px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(700px);
    transform: perspective(700px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}